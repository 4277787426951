
import React, { Component } from 'react'
//import {Paper} from '@mui/material'
import { Avatar, Box, Container,Link, Paper,Modal ,Typography ,MenuItem,IconButton,Button, Divider, AppBar, Card} from '@mui/material'
import man from '../src/img/logo.png'
import MenuIcon from '@mui/icons-material/Menu';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';

import { useNavigate,useLocation,useMatch } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';

import logo from '../src/img/logo.png'


export class Appheader extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       open:false,
       data:"",
       textcolor:"#33339c",
       bgcolor:"",
 
       id:1,
       pathname:this.props.location.pathname,
 
       opencompaign:false,
       openrole:false,

    }
  }


  

  render() {
    return (
      <div>
     <Box sx={{ flexGrow: 1 }}>
      <AppBar elevation={2} position="fixed" sx={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#fff',boxShadow:'#a0a0a0'}}>
   
       <img src={logo} style={{height:40,width:100,objectFit:'contain'}}/>
      
  </AppBar>
  </Box>
  
  </div>
    )
  }
}

export default Appheader

export function Appheaderc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Appheader location={location} navigate={navigate}></Appheader>)
}


