import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment"


import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import base_url from "./baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const drawerWidth = 80;

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",


      id: "",
      is_addusers: false,
      is_editusers: false,

      userList: [],

      page: 0,
      rowsPerPage: 10,
      counts: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    fetch(`${base_url.base_url}/retriveContactList`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        rowsPerPage: 10,
        page: 0,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({ userList: result.data, counts: result.size });
      });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      fetch(`${base_url.base_url}/retriveContactList`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          rowsPerPage: this.state.rowsPerPage,
          page: this.state.page,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          this.setState({ userList: result.data, counts: result.size });
        });
    });
  };



  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 }, () => {
      fetch(`${base_url.base_url}/retriveContactList`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          rowsPerPage: this.state.rowsPerPage,
          page: this.state.page,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          this.setState({ userList: result.data, counts: result.size });
        });
    });
  };

  autoUpdateFunction = () => {
    fetch(`${base_url.base_url}/retriveContactList`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        rowsPerPage: this.state.rowsPerPage,
        page: this.state.page,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({ userList: result.data, counts: result.size });
      });
  };




  updateUserData = () => {
    if (
      this.state.name !== "" &&
      this.state.email !== "" &&
      this.state.phone !== "" &&
      this.state.password !== ""
    ) {
      fetch(`${base_url.base_url}/updateUserDetails`, {
        headers: {
          "content-type": "application/json",
        },
        method: "put",
        body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          address: this.state.address,
          id: this.state.id,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then(async(result) => {
          this.autoUpdateFunction();
          this.empupdated();
          this.setState(
            {
              name: "",
              email: "",
              phone: "",
              password: "",
              id: "",
              is_addusers: false,
              is_editusers: false,
            },
          );
        });
    } else {
      this.fieldunfilled();
    }
  };

  succes = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Employee Successfully Added
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  fieldsdeleted = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Contact deleted{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  empupdated = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Contact updated{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  userExists = () => {
    toast.error(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Contact Exist{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },ml:{sm:10,xs:0} }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Contact List
              </Typography>

         
              <Paper sx={{ width: "100%", minHeight: 200, mt: 2 }}>
                <Box sx={{ mt: 0, padding: 2 }}>
                  <TableContainer component={Box}>
                    <Table
                      sx={{ minWidth: 1220 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            id
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Name
                          </TableCell>
                            
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Created at
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.userList.map((row,i) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "#42526e", fontSize: 13 }}
                            >
                              <Button
                                variant="contained"
                                disableElevation
                                sx={{
                                  height: 20,
                                  fontSize: 11,
                                  width:10,
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {i + this.state.rowsPerPage * this.state.page + 1 }
                              </Button>
                            </TableCell>
                        
                            <TableCell align="left" sx={{ color: "#42526e",fontSize:12 }}>
                              {row.name}
                            </TableCell>  
                            
                              <TableCell align="left" sx={{ color: "#42526e",fontSize:12 }}>
                           {moment(row.created_at).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                              {row.mob_no}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                              {row.email}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#42526e" ,fontSize:12}}>
                              {row.desc}
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "right",
                                }}
                              >
                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() => {
                                      if (window.confirm("Do you really want to delete?")) {
                                      
                                      fetch(
                                        `${base_url.base_url}/deleteContact`,
                                        {
                                          headers: {
                                            "content-type": "application/json",
                                          },
                                          method: "delete",
                                          body: JSON.stringify({
                                            id: row._id,
                                          }),
                                        }
                                      )
                                        .then((res) => {
                                          return res.json();
                                        })
                                        .then((result) => {
                                          this.autoUpdateFunction();
                                          this.fieldsdeleted();
                                        });

                                      }
                                    }}
                                    size="small"
                                  >
                                    <DeleteForeverIcon
                                      sx={{
                                        color: "#f29494",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>
              </Paper>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 80 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>
    

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    );
  }
}

export default Contact;