import React, { Component } from "react";
import { Sidebarc } from "../Sidebar";
import { Appheaderc } from "../Appheader";
import { Button, Grid2, Paper, SpeedDialIcon, Switch, Typography } from "@mui/material";
import { Box, Tabs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import {
  Tooltip,
  Tab,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
} from "@mui/material";
import base_url from "../baseurl";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import { useLocation, useNavigate, useParams } from "react-router-dom";


const drawerWidth = 80;
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "subscription",

      //////// create plan ///////
      is_add_plan: false,
      period: "monthly",
      interval: 12,
      name: "",
      amount: 1200,
      currency: "INR",
      description: "",
      notes_key_1: "",
      notes_key_2: "",
      plan_id: "",
      planList: [],
      plan_count: 0,
      plan_page: 0,
      plan_rowPerPage: 100,

      ////////////subscription section //
      subscriptionList: [],
      subscription_count: 0,
      subscription_page: 0,
      subscription_rowPerPage: 100,

      /////////// alll invove against subsction //
      allInvoiceAgainstSubscription: [],
      allInvoiceAgainstSubscription_count: 0,
      is_all_invoce_against_subscription: false,

      ///////// customer detail

      cust_email: "",
      cust_phone: "",
      cust_name: "",
      cust_gst: "",
      cust_id: "",
      is_customer_details_open: false,
      is_add_customer: false,
      is_edit_customer: false,
      customerList: [],
      customerList_size: 0,
      cust_page: 0,
      cust_rowsPerPage: 100,

      //////// package ////////////
      package_name: "",
      package_amount: "",
      package_duration: "",
      package_type: "",
      package_discount: "",
      package_plan_id: "",
      package_plan_name: "",
      is_package_add: false,
      is_edit_package: false,
      package_id: "",

      package_page: 0,
      package_rowsPerPage: 10,
      packageList: [],
      package_count: 0,

/////////////////// refund list //////
refund_page: 0,
refund_rowsPerPage: 100,
refundList: [],
refund_count: 0,



    };
    this.handleChange = this.handleChange.bind();
  }

  componentDidMount() {
    this.instantRetriveSubscriptionList(); //// retrive all subscription

    this.instantRetrivePlanList(); // calling plan retrive

   // this.retriveCustomerList(); /// retrving customer list

    this.retrivePackageList(); /// retrive package list

    this.retriveRefundList()  //// retrive refund lst
  }

  instantRetrivePlanList = () => {
    fetch(`${base_url.base_url}/transaction/plan/retrivePlanList`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        page: this.state.plan_page,
        rowsPerPage: this.state.plan_rowPerPage,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          planList: result.planList.items,
          plan_count: result.planList.count,
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  ////////////// plan section and details ////////////////
  createPlan = () => {
    if (
      this.state.period !== "" &&
      this.state.interval > 0 &&
      this.state.name !== "" &&
      this.state.amount > 0 &&
      this.state.description !== ""
    ) {
      fetch(`${base_url.base_url}/transaction/plan/createPlan`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          period: this.state.period,
          interval: this.state.interval,
          name: this.state.name,
          amount: this.state.amount * 100,
          currency: "INR",
          description: this.state.description,
          notes_key_1: this.state.notes_key_1,
          notes_key_2: this.state.notes_key_2,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          alert("plan created");
          this.setState({
            period: "",
            is_add_plan:false,
            interval: "",
            name: "",
            amount: 0,
            currency: "INR",
            description:"",
            notes_key_1: "",
            notes_key_2: "",
          })
          this.instantRetrivePlanList();
        });
    } else {
      alert("eror");
    }
  };

  handleChangePage = (event, newPage) => {
    this.setState({ plan_page: newPage }, () => {
      this.instantRetrivePlanList();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ plan_rowPerPage: parseInt(event.target.value, 10) });
    this.setState({ plan_page: 0 }, () => {
      this.instantRetrivePlanList();
    });
  };

  ////// subscription section ////
  instantRetriveSubscriptionList = () => {
    fetch(
      `${base_url.base_url}/transaction/subscription/retriveSubscriptionList`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          page: this.state.subscription_page,
          rowsPerPage: this.state.subscription_rowPerPage,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result);
        this.setState({
          subscriptionList: result.subscriptionList.items,
          subscription_count: result.subscriptionList.count,
        });
      });
  };

  handleChangePage_subscription = (event, newPage) => {
    this.setState({ subscription_page: newPage }, () => {
      this.instantRetriveSubscriptionList();
    });
  };

  handleChangeRowsPerPage_subscription = (event) => {
    this.setState({
      subscription_rowPerPage: parseInt(event.target.value, 10),
    });
    this.setState({ subscription_page: 0 }, () => {
      this.instantRetriveSubscriptionList();
    });
  };

  ////// retrivve all invoice against subscription
  retriveAllInvoiceAgainstSubscription = (subscription_id) => {
    this.setState({ is_all_invoce_against_subscription: true });
    fetch(
      `${base_url.base_url}/transaction/subscription/retriveAllInvoiceAgainstASubscription`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          subscription_id: subscription_id,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          allInvoiceAgainstSubscription: result.data.items,
          allInvoiceAgainstSubscription_count: result.data.count,
        });
      });
  };

  ///////  customer section below
  retriveSingleCustomerDetails = (customer_id) => {
    this.setState({ is_all_invoce_against_subscription: true });
    fetch(
      `${base_url.base_url}/transaction/customer/retriveSingleCustomerDetails`,
      {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          customer_id: customer_id,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          cust_email: result.customerDetails.email,
          cust_phone: result.customerDetails.contact,
          cust_name: result.customerDetails.name,
          cust_gst: result.customerDetails.gstin,
          cust_id: result.customerDetails.id,
          is_customer_details_open: true,
        });
      });
  };



  createNewCustomer = () => {
    if (
      this.state.cust_email !== "" &&
      this.state.cust_phone !== "" &&
      this.state.cust_name !== ""
    ) {
      fetch(`${base_url.base_url}/transaction/customer/addNewCustomer`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          cust_email: this.state.cust_email,
          cust_phone: this.state.cust_phone,
          cust_name: this.state.cust_name,
          cust_gst: this.state.cust_gst,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
          alert("new customer added");
        });
    } else {
      alert("fill all fields");
    }
  };

  retriveCustomerList = () => {
    fetch(`${base_url.base_url}/transaction/customer/retriveCustomerList`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        page: this.state.cust_page,
        rowsPerPage: this.state.cust_rowsPerPage,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          customerList: result.customerData.items,
          customerList_size: result.customerData.count,
        });
      });
  };

  handleChangePage_customer = (event, newPage) => {
    this.setState({ cust_page: newPage }, () => {
      this.retriveCustomerList();
    });
  };

  handleChangeRowsPerPage_customer = (event) => {
    this.setState({ cust_rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ cust_page: 0 }, () => {
      this.retriveCustomerList();
    });
  };

  ///////////// package ////////////////
  createPackage = () => {
    fetch(`${base_url.base_url}/transaction/package/addNewPackage`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        package_name: this.state.package_name,
        amount: this.state.package_amount,
        duration: this.state.package_duration,
        package_type: this.state.package_type,
        discount: this.state.package_discount,
        plan_id: this.state.package_plan_id,
        plan_name: this.state.package_plan_name,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          package_id :"",
          package_name: "",
          package_amount:"",
          package_duration:"",
          package_type: "",
          package_discount :"",
          package_plan_id :"",
          package_plan_name:"",
          is_package_add:false
        })
        alert("new packages added");
      });
  };

  retrivePackageList = () => {
    fetch(`${base_url.base_url}/transaction/package/retrivePackList`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        page: this.state.package_page,
        rowsPerPage: this.state.package_rowsPerPage,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        console.log(result);
        this.setState({
          packageList: result.data,
          package_count: result.size,
        });
      });
  };

  editPackage = () => {
    fetch(`${base_url.base_url}/transaction/package/editPackage`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        package_id: this.state.package_id,
        package_name: this.state.package_name,
        amount: this.state.package_amount,
        duration: this.state.package_duration,
        package_type: this.state.package_type,
        discount: this.state.package_discount,
        plan_id: this.state.package_plan_id,
        plan_name: this.state.package_plan_name,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          package_id :"",
          package_name: "",
          package_amount:"",
          package_duration:"",
          package_type: "",
          package_discount :"",
          package_plan_id :"",
          package_plan_name:"",
          is_edit_package:false
        })
        alert("new packages updated");
      });
  };

  deletePackage = (id) => {
    let passcode = prompt("Enter passcode");
    if (passcode == 1234567890) {
    fetch(`${base_url.base_url}/transaction/package/deletePackage`, {
      headers: {
        "content-type": "application/json",
      },
      method: "delete",
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        alert("Package deleted sucessfully");
        this.retrivePackageList();
      });

    } else {
     alert('enter correct passcode')
    }
  };



  handleChangePage_package = (event, newPage) => {
    this.setState({ package_page: newPage }, () => {
      this.retrivePackageList();
    });
  };

  handleChangeRowsPerPage_package = (event) => {
    this.setState({ package_rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ package_page: 0 }, () => {
      this.retrivePackageList();
    });
  };



  //////////////// refund list


  retriveRefundList = () => {
    fetch(`${base_url.base_url}/transaction/refund/retriveAllRefund`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post",
      body: JSON.stringify({
        page: this.state.refund_page,
        rowsPerPage: this.state.refund_rowsPerPage,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          refund_count : result.refundList.count,
          refundList: result.refundList.items,
        })
      });
  };


  handleChangePage_refund = (event, newPage) => {
    this.setState({ refund_page: newPage }, () => {
      this.retriveRefundList();
    });
  };

  handleChangeRowsPerPage_refund = (event) => {
    this.setState({ refund_rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ refund_page: 0 }, () => {
      this.retriveRefundList();
    });
  };



  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" },
              ml: { sm: 10, xs: 0 },
            }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Subscriptions : -
              </Typography>

              <Box sx={{ minHeight: 600, width: "100%" }}>
                <Paper
                  elevation={1}
                  variant="outlined"
                  sx={{
                    mt: 1,
                    height: 45,
                    width: "100%",
                    borderColor: "#e9edee",
                    backgroundColor:'#5da7db',
                    display:'flex',
                    alignItems:'center',
                    borderRadius: 0,
                  }}
                >
                 <Typography sx={{ml:2,fontWeight:'bold',color:'#fff'}}>Banking Modules</Typography>
                </Paper>

                {
                  /// all subscription
                }
                <Paper
                  elevation={1}
                  variant="outlined"
                  sx={{
                    mt: 1,
                    minHeight: 400,
                    width: "100%",
                    borderRadius: 0,
                    borderColor: "#e9edee",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    <Tabs
                      value={this.state.tabs}
                      onChange={this.handleChange}
                      aria-label="wrapped label tabs example"
                    >
                      <Tab
                        value="subscription"
                        label="Subscriptions"
                        onClick={() => this.setState({ tabs: "subscription" })}
                        sx={{ color: "black" }}
                        wrapped
                      />
                      <Tab
                        value="plan"
                        label="Plan"
                        onClick={() => this.setState({ tabs: "plan" })}
                        sx={{ color: "black" }}
                        wrapped
                      />
                      <Tab
                        value="customer"
                        label="Customer"
                        onClick={() => this.setState({ tabs: "customer" })}
                        sx={{ color: "black",display:'none' }}
                        wrapped
                      />
                      <Tab
                        value="package"
                        label="Packages"
                        onClick={() => this.setState({ tabs: "package" })}
                        sx={{ color: "black" }}
                        wrapped
                      />
                         <Tab
                        value="refunds"
                        label="Refund"
                        onClick={() => this.setState({ tabs: "refunds" })}
                        sx={{ color: "black" }}
                        wrapped
                      />
                    </Tabs>
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      sx={{
                        fontSize: 11,
                        fontWeight: 400,
                        display: this.state.tabs == "plan" ? "block" : "none",
                      }}
                      onClick={() => this.setState({ is_add_plan: true })}
                    >
                      Create New Plan
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      sx={{
                        fontSize: 11,
                        fontWeight: 400,
                        display:
                          this.state.tabs == "customer" ? "block" : "none",
                      }}
                      onClick={() => this.setState({ is_add_customer: true })}
                    >
                      Create Customer
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disableElevation
                      sx={{
                        fontSize: 11,
                        fontWeight: 400,
                        display:
                          this.state.tabs == "package" ? "block" : "none",
                      }}
                      onClick={() => this.setState({ is_package_add: true })}
                    >
                      Create New Package
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      height: 30,
                      backgroundColor: "#fdf8e4",
                      width: "100%",
                    }}
                  ></Box>
                  <Divider />
                  <Box
                    sx={{
                      minHeight: 80,
                      backgroundColor: "#fbfcff",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ ml: 1, mr: 1 }}>
                      <Grid2 container spacing={2}>
                        <Grid2 item size={{ xs: 12, sm: 6, md: 2.5 }}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: 60,
                              width: "100%",
                              backgroundColor: "#fff",
                              display: "flex",
                              borderLeft: 3,
                              borderLeftColor: "green",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                height: 60,
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: 27 }}>
                                {this.state.subscription_count}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                height: 60,
                                width: "85%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                }}
                              >
                                Total subscriptions
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid2>

                        <Grid2 item size={{ xs: 12, sm: 6, md: 2.5 }}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: 60,
                              width: "100%",
                              backgroundColor: "#fff",
                              display: "flex",
                              borderLeft: 3,
                              borderLeftColor: "red",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                height: 60,
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: 27 }}>
                                {this.state.plan_count}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                height: 60,
                                width: "85%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                }}
                              >
                                Total Plan
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid2>

                        <Grid2 item size={{ xs: 12, sm: 6, md: 2.5 }}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: 60,
                              width: "100%",
                              backgroundColor: "#fff",
                              display: "flex",
                              borderLeft: 3,
                              borderLeftColor: "blue",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                height: 60,
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: 27 }}>{this.state.refund_count}</Typography>
                            </Box>
                            <Box
                              sx={{
                                height: 60,
                                width: "85%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                }}
                              >
                                Total refunds
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid2>

                        <Grid2 item size={{ xs: 12, sm: 6, md: 2.5 }}>
                          <Paper
                            variant="outlined"
                            sx={{
                              height: 60,
                              width: "100%",
                              backgroundColor: "#fff",
                              display: "flex",
                              borderLeft: 3,
                              borderLeftColor: "orange",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                height: 60,
                                width: "15%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography sx={{ fontSize: 27 }}>
                                {this.state.package_count}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                height: 60,
                                width: "85%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                }}
                              >
                                Total Packages
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid2>
                      </Grid2>
                    </Box>
                  </Box>
                  <Divider />

                  {
                    /// plan list
                  }
                  <Box
                    sx={{
                      display: this.state.tabs == "plan" ? "block" : "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        mt: 1,
                        mb: 1,
                        ml: 2,
                        color: "#ab266c",
                      }}
                    >
                      PLAN LIST
                    </Typography>
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ minHeight: 250, width: "100%" }}
                      >
                        <TableContainer
                          component={Box}
                          sx={{ backgroundColor: "#f4f4f6" }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PLAN ID
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PLAN NAME
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  ENTITY
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  INTERVAL
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PERIOD
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  AMOUNT
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CURRENCY
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  {" "}
                                  IS_ACTIVE
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.planList.map((row, index) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "#42526e" }}
                                  >
                                    <Box
                                      sx={{
                                        height: 20,
                                        minWidth: 60,
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "700",
                                          paddingLeft: 1,
                                          paddingRight: 1,
                                        }}
                                      >
                                        {row.id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {row.item.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.entity}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.interval}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {row.period}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.item.amount / 100} INR
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.item.currency}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    <Switch
                                      size="small"
                                      checked={row.item.active}
                                      onClick={() => {}}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Divider />
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={this.state.plan_count}
                          rowsPerPage={this.state.plan_rowPerPage}
                          page={this.state.plan_page}
                          onPageChange={this.handleChangePage}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                        <Divider />
                      </Paper>
                    </Box>
                  </Box>

                  {
                    //// subscription list
                  }

                  <Box
                    sx={{
                      display:
                        this.state.tabs == "subscription" ? "block" : "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        mt: 1,
                        mb: 1,
                        ml: 2,
                        color: "#ab266c",
                      }}
                    >
                      SUBSCRIPTIONS LIST
                    </Typography>
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ minHeight: 250, width: "100%" }}
                      >
                        <TableContainer
                          component={Box}
                          sx={{ backgroundColor: "#f4f4f6" }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 2250 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  SUBSCRIPTION ID
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  ENTITY
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PLAN ID
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CUSTOMER ID
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  TOTAL COUNT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  REMAINING COUNT
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PAID COUNT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  START AT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CREATED AT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  EXPIRE AT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  {" "}
                                  IS_ACTIVE
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  {" "}
                                  STATUS
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  {" "}
                                  ACTION
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.subscriptionList.map((row, index) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "#42526e" }}
                                  >
                                    <Box
                                      sx={{
                                        height: 20,
                                        minWidth: 60,
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        onClick={this.retriveAllInvoiceAgainstSubscription.bind(
                                          this,
                                          row.id
                                        )}
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "700",
                                          paddingLeft: 1,
                                          paddingRight: 1,
                                          textDecoration:'underline'
                                        }}
                                      >
                                      {row.id} 
                                      </Typography>
                                      <PreviewIcon onClick={()=>this.props.navigate(`/subscription_desc/:${row.id}`)} sx={{height:16,width:16,color:'#04afaf',ml:1}}/>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.entity}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {row.plan_id}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    <Box
                                     // onClick={this.retriveSingleCustomerDetails.bind(
                                      //  this,
                                     //   row.customer_id
                                     // )}
                                    >
                                      {row.customer_id == null
                                        ? "--------------"
                                        : row.customer_id}
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {row.total_count}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.remaining_count}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.paid_count}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {moment
                                      .unix(row.start_at)
                                      .format("MM/DD/YYYY")}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {moment
                                      .unix(row.created_at)
                                      .format("MM/DD/YYYY")}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {moment
                                      .unix(row.expire_by)
                                      .format("MM/DD/YYYY")}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    <Switch
                                      size="small"
                                      checked={
                                        row.status == "active" ? true : false
                                      }
                                      onClick={() => {
                                        if (row.status == "active") {
                                          fetch(
                                            `${base_url.base_url}/transaction/subscription/pushSubscription`,
                                            {
                                              headers: {
                                                "content-type":
                                                  "application/json",
                                              },
                                              method: "put",
                                              body: JSON.stringify({
                                                subscription_id: row.id,
                                              }),
                                            }
                                          )
                                            .then((res) => {
                                              return res.json();
                                            })
                                            .then((result) => {
                                              this.instantRetriveSubscriptionList();
                                            });
                                        }

                                        if (row.status == "paused") {
                                          fetch(
                                            `${base_url.base_url}/transaction/subscription/resumeSubscription`,
                                            {
                                              headers: {
                                                "content-type":
                                                  "application/json",
                                              },
                                              method: "put",
                                              body: JSON.stringify({
                                                subscription_id: row.id,
                                              }),
                                            }
                                          )
                                            .then((res) => {
                                              return res.json();
                                            })
                                            .then((result) => {
                                              this.instantRetriveSubscriptionList();
                                            });
                                        }
                                      }}
                                    />
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        height: 25,
                                        minWidth: 25,
                                        fontWeight: "bold",
                                        backgroundColor: "pink",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {row.status}
                                    </Box>
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      onClick={() => {
                                        let passcode = prompt(
                                          "Please enter security passcode"
                                        );
                                        if (passcode == 1234567890) {
                                          fetch(
                                            `${base_url.base_url}/transaction/subscription/cancelSubscriptionByAdmin`,
                                            {
                                              headers: {
                                                "content-type":
                                                  "application/json",
                                              },
                                              method: "post",
                                              body: JSON.stringify({
                                                subscription_id: row.id,
                                              }),
                                            }
                                          )
                                            .then((res) => {
                                              return res.json();
                                            })
                                            .then((result) => {
                                              this.instantRetriveSubscriptionList();
                                              alert('Subscription cancelled')
                                            });
                                        } else {
                                          alert("Invalid passcode");
                                        }
                                      }}
                                      color="error"
                                      sx={{ fontSize: 9, height: 20 }}
                                      variant="contained"
                                    >
                                      Cancel
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Divider />
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={this.state.subscription_count}
                          rowsPerPage={this.state.subscription_rowPerPage}
                          page={this.state.subscription_page}
                          onPageChange={this.handleChangePage}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                        <Divider />
                      </Paper>
                    </Box>
                  </Box>

                  {
                    //// customer list
                  }
                  <Box
                    sx={{
                      display: this.state.tabs == "customer" ? "block" : "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        mt: 1,
                        mb: 1,
                        ml: 2,
                        color: "#ab266c",
                      }}
                    >
                      CUSTOMER LIST
                    </Typography>
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ minHeight: 250, width: "100%" }}
                      >
                        <TableContainer
                          component={Box}
                          sx={{ backgroundColor: "#f4f4f6" }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CUSTOMER ID
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  NAME
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CONTACT
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  EMAIL
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  GST NO
                                </TableCell>

                                
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.customerList.map((row, index) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "#42526e" }}
                                  >
                                    <Box
                                      sx={{
                                        height: 20,
                                        minWidth: 60,
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "500",
                                          paddingLeft: 1,
                                          paddingRight: 1,
                                        }}
                                      >
                                        {row.id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {row.name == null? "---" : row.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.contact}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                     // textTransform: "uppercase",
                                    }}
                                  >
                                    {row.email == null? "---" : row.email}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {row.gst == null ? "-----" : row.gst}
                                  </TableCell>

                                 
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Divider />
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={this.state.customerList_size}
                          rowsPerPage={this.state.cust_rowsPerPage}
                          page={this.state.cust_page}
                          onPageChange={this.handleChangePage_customer}
                          onRowsPerPageChange={
                            this.handleChangeRowsPerPage_customer
                          }
                        />
                        <Divider />
                      </Paper>
                    </Box>
                  </Box>

                  {
                    //// package list
                  }
                  <Box
                    sx={{
                      display: this.state.tabs == "package" ? "block" : "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        mt: 1,
                        mb: 1,
                        ml: 2,
                        color: "#ab266c",
                      }}
                    >
                      PACKAGES LIST
                    </Typography>
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ minHeight: 250, width: "100%" }}
                      >
                        <TableContainer
                          component={Box}
                          sx={{ backgroundColor: "#f4f4f6" }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 1250 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PACKAGE ID
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PACKAGE NAME
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  AMOUNT
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  DURATION
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PACKAGE TYPE
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  DISCOUNT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PLAN NAME
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PLAN ID
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  {" "}
                                  ACTION
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.packageList.map((row, index) => (
                                <TableRow
                                  key={row.package_id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "#42526e" }}
                                  >
                                    <Box
                                      sx={{
                                        height: 20,
                                        minWidth: 60,
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "700",
                                          paddingLeft: 1,
                                          paddingRight: 1,
                                        }}
                                      >
                                        {row.package_id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {row.package_name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.amount}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {row.duration}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {row.package_type}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.discount}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.plan_name}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    {row.plan_id}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                    }}
                                  >
                                    <Tooltip title="edit">
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          this.setState({
                                            package_name: row.package_name,
                                            package_amount: row.amount,
                                            package_duration: row.duration,
                                            package_type: row.package_type,
                                            package_discount: row.discount,
                                            package_plan_id: row.plan_id,
                                            package_plan_name: row.plan_name,
                                            is_package_add: false,
                                            is_edit_package: true,
                                            package_id: row.package_id,
                                          })
                                        }
                                      >
                                        <BorderColorIcon
                                          sx={{
                                            height: 14,
                                            width: 14,
                                            color: "blue",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip title="delete">
                                      <IconButton
                                        size="small"
                                        onClick={this.deletePackage.bind(
                                          this,
                                          row._id
                                        )}
                                      >
                                        <DeleteIcon
                                          sx={{
                                            height: 14,
                                            width: 14,
                                            color: "#ab266c",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Divider />
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={this.state.package_count}
                          rowsPerPage={this.state.package_rowsPerPage}
                          page={this.state.package_page}
                          onPageChange={this.handleChangePage_package}
                          onRowsPerPageChange={this.handleChangePage_package}
                        />
                        <Divider />
                      </Paper>
                    </Box>
                  </Box>


{
  ////// refundList
}
<Box
                    sx={{
                      display: this.state.tabs == "refunds" ? "block" : "none",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        mt: 1,
                        mb: 1,
                        ml: 2,
                        color: "#ab266c",
                      }}
                    >
                      REFUND LIST
                    </Typography>
                    <Box>
                      <Paper
                        variant="outlined"
                        sx={{ minHeight: 250, width: "100%" }}
                      >
                        <TableContainer
                          component={Box}
                          sx={{ backgroundColor: "#f4f4f6" }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 1250 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  REFUND ID
                                </TableCell>
                                <TableCell
                                  align="left"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  ENTITY
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  AMOUNT (INR)
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  PAYMENT ID
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  CREATED AT
                                </TableCell>

                                <TableCell
                                  align="center"
                                  sx={{
                                    fontSize: 12,
                                    color: "#008ffb",
                                    fontFamily: "800",
                                  }}
                                >
                                  STATUS
                                </TableCell>

                              
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.refundList.map((row, index) => (
                                <TableRow
                                  key={row.package_id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    backgroundColor:
                                      index % 2 ? "#f9f9f9" : "#fff",
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ color: "#42526e" }}
                                  >
                                    <Box
                                      sx={{
                                        height: 20,
                                        minWidth: 60,
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "500",
                                          paddingLeft: 1,
                                          paddingRight: 1,
                                        }}
                                      >
                                        {row.id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    REFUND
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                     // textTransform: "uppercase",
                                    }}
                                  >
                                    {row.amount/100} INR
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    //  textTransform: "uppercase",
                                    }}
                                  >
                                    {row.payment_id}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      fontWeight: "500",
                                    }}
                                  >
                                    {moment.unix(row.created_at).format('YYYY-MM-DD')}
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      color: "#42526e",
                                      fontSize: 12,
                                      minWidth: 100,
                                      color:'#007c00'
                                    }}
                                  >
                                    {row.status}
                                  </TableCell>

                                    
                              
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Divider />
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={this.state.refund_count}
                          rowsPerPage={this.state.refund_rowsPerPage}
                          page={this.state.refund_page}
                         onPageChange={this.handleChangePage_refund}
                          onRowsPerPageChange={this.handleChangeRowsPerPage_refund}
                        />
                        <Divider />
                      </Paper>
                    </Box>
                  </Box>





                </Paper>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 80 },
            }}
          >
            <Appheaderc />
          </Box>

          {
            ////// create new plan form
          }
          <Box>
            <Modal
              open={this.state.is_add_plan}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper
                      onClick={() =>
                        this.setState({
                          is_add_plan: false,
                          period: "monthly",
                          interval: 12,
                          name: "",
                          amount: 1200,
                          currency: "INR",
                          description: "",
                          notes_key_1: "",
                          notes_key_2: "",
                        })
                      }
                      elevation={5}
                      sx={{
                        height: 30,
                        width: 30,
                        borderRadius: 1,
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: -1,
                        mt: -1,
                      }}
                    >
                      <CloseIcon
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                      />
                    </Paper>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    Create New Plan
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 140,
                      backgroundColor: "#1b7bce",
                      marginLeft: { xs: 2, sm: 4 },
                      mb: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "5px",
                        borderRadius: 10,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: { xs: 2, sm: 4 },
                        paddingRight: { xs: 2, sm: 4 },
                        height: "76vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        Plan Name
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Intervals
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="interval"
                        value={this.state.interval}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Amount (INR)
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="number"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="amount"
                        value={this.state.amount}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Periods<Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        id="outlined-select-currency"
                        select
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        defaultValue="monthly"
                      >
                        {plan_list.map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            sx={{ fontSize: 12, fontWeight: "600" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Description
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="description"
                        onChange={this.handleChange}
                        rows={3}
                        multiline
                        value={this.state.description}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 3,
                          color: "#1b7bce",
                          mb: 2,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Notes Details : -
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Note key 1
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="notes_key_1"
                        onChange={this.handleChange}
                        rows={2}
                        multiline
                        value={this.state.notes_key_1}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Note key 2
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="notes_key_2"
                        onChange={this.handleChange}
                        rows={2}
                        multiline
                        value={this.state.notes_key_2}
                        fullWidth
                        size="small"
                      />

                      <Button
                        onClick={this.createPlan}
                        variant="contained"
                        disableElevation
                        size="small"
                        sx={{
                          backgroundColor: "#2486bb",
                          mt: 2,
                          textTransform: "none",
                        }}
                      >
                        Create New Plan
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {
            //// show all transaction for a subscription  id
          }
          <Box>
            <Modal
              open={this.state.is_all_invoce_against_subscription}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "90%", lg: "90%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper>
                      <CloseIcon
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                        onClick={() =>
                          this.setState({
                            is_all_invoce_against_subscription: false,
                            allInvoiceAgainstSubscription : []
                          })
                        }
                      />
                    </Paper>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    All invoice against subscription
                  </Typography>

                  <Box sx={{ paddingLeft: 4, paddingRight: 4 }}>
                    <Box sx={{ display: "block" }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          mt: 1,
                          mb: 1,
                          ml: 2,
                          color: "#ab266c",
                        }}
                      >
                        INVOICE LIST
                      </Typography>
                      <Box>
                        <Paper
                          variant="outlined"
                          sx={{ minHeight: 250, width: "100%" }}
                        >
                          <TableContainer
                            component={Box}
                            sx={{ backgroundColor: "#f4f4f6" }}
                          >
                            <Table
                              size="small"
                              sx={{ minWidth: 1250 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    INVOICE ID
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    ENTITY
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    CUSTOMER ID
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    ORDER ID
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    SUBSCRIPTION ID
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    PAYMENT ID
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    PAID AT
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    AMOUNT
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    {" "}
                                    STATUS
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    sx={{
                                      fontSize: 12,
                                      color: "#008ffb",
                                      fontFamily: "800",
                                    }}
                                  >
                                    {" "}
                                    ACTION
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.allInvoiceAgainstSubscription.map(
                                  (row, index) => (
                                    <TableRow
                                      key={row.id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                        backgroundColor:
                                          index % 2 ? "#f9f9f9" : "#fff",
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{ color: "#42526e" }}
                                      >
                                        <Box
                                          sx={{
                                            height: 20,
                                            minWidth: 60,
                                            display: "flex",
                                            justifyContent: "left",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: 12,
                                              fontWeight: "700",
                                              paddingLeft: 1,
                                              paddingRight: 1,
                                            }}
                                          >
                                            {row.id}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          fontWeight: "500",
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {row.entity}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          fontWeight: "600",
                                          // textTransform: "uppercase",
                                        }}
                                      >
                                        {row.customer_id}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          fontWeight: "600",
                                          //textTransform: "uppercase",
                                        }}
                                      >
                                        {row.order_id}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          fontWeight: "600",
                                        }}
                                      >
                                        {row.subscription_id}
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          minWidth: 100,
                                        }}
                                      >
                                        {row.payment_id}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          minWidth: 100,
                                        }}
                                      >
                                        {moment
                                          .unix(row.paid_at)
                                          .format("MM-DD-YYYY")}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          minWidth: 100,
                                        }}
                                      >
                                        {row.amount / 100} INR
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          minWidth: 100,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{ height: 20, fontSize: 12 }}
                                          size="small"
                                        >
                                          {row.status}
                                        </Button>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        sx={{
                                          color: "#42526e",
                                          fontSize: 12,
                                          minWidth: 100,
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          sx={{
                                            height: 30,
                                            fontSize: 12,
                                            backgroundColor: "#ab266c",
                                          }}
                                          size="small"

                                          onClick={()=>{
                                                   
                                            let passcode = prompt("Enter security passcode");
                                              if(passcode == 1234567890) {
                                               fetch(
                                              `${base_url.base_url}/transaction/refund/getRefundToCustomer`,
                                              {
                                                headers: {
                                                  "content-type": "application/json",
                                                },
                                                method: "post",
                                                body: JSON.stringify({
                                                  paymentId: row.payment_id,
                                                  amount : row.amount
                                                }),
                                              }
                                            )
                                              .then((res) => {
                                                return res.json();
                                              })
                                              .then((result) => {

                                               alert(result.message)

                                              })
                                            } else {
                                                alert('Invalid passcode')
                                                         }


                                        



                                          }}
                                        >
                                          Refund
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                            <Divider />
                          </TableContainer>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={
                              this.state.allInvoiceAgainstSubscription_count
                            }
                            rowsPerPage={
                              this.state.allInvoiceAgainstSubscription_count
                            }
                            page={0}
                            //  onPageChange={this.handleChangePage}
                            // onRowsPerPageChange={this.handleChangeRowsPerPage}
                          />
                        </Paper>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {
            ///// get customer details by customer id
          }
          <Box>
            <Modal
              open={this.state.is_customer_details_open}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper>
                      <CloseIcon
                        onClick={() =>
                          this.setState({ is_customer_details_open: false })
                        }
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                      />
                    </Paper>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    Customer details : -
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 140,
                      backgroundColor: "#1b7bce",
                      marginLeft: { xs: 2, sm: 4 },
                      mb: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "5px",
                        borderRadius: 10,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: { xs: 2, sm: 4 },
                        paddingRight: { xs: 2, sm: 4 },
                        height: "76vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        Customer Name
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_name"
                        value={this.state.cust_name}
                        //  onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Email
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_email"
                        value={this.state.cust_email}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Phone
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_phone"
                        value={this.state.cust_phone}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Gst No
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-select-currency"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        name="cust_gst"
                        onChange={this.handleChange}
                        value={this.state.cust_gst}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {
            ///// cretae new customer
          }
          <Box>
            <Modal
              open={this.state.is_add_customer}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper>
                      <CloseIcon
                        onClick={() =>
                          this.setState({ is_add_customer: false })
                        }
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                      />
                    </Paper>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    Customer details : -
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 140,
                      backgroundColor: "#1b7bce",
                      marginLeft: { xs: 2, sm: 4 },
                      mb: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "5px",
                        borderRadius: 10,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: { xs: 2, sm: 4 },
                        paddingRight: { xs: 2, sm: 4 },
                        height: "76vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        Customer Name
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_name"
                        value={this.state.cust_name}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Email
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_email"
                        value={this.state.cust_email}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Phone
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="cust_phone"
                        value={this.state.cust_phone}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Customer Gst No
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-select-currency"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        name="cust_gst"
                        onChange={this.handleChange}
                        value={this.state.cust_gst}
                      />

                      <Button
                        onClick={this.createNewCustomer}
                        variant="contained"
                        disableElevation
                        size="small"
                        sx={{
                          backgroundColor: "#2486bb",
                          mt: 2,
                          textTransform: "none",
                        }}
                      >
                        Create New Customer
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {
            //// create new packages
          }
          <Box>
            <Modal
              open={this.state.is_package_add}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper>
                      <CloseIcon
                        onClick={() => this.setState({ is_package_add: false })}
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                      />
                    </Paper>
                  </Box>


                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    Package details : -
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 140,
                      backgroundColor: "#1b7bce",
                      marginLeft: { xs: 2, sm: 4 },
                      mb: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "5px",
                        borderRadius: 10,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: { xs: 2, sm: 4 },
                        paddingRight: { xs: 2, sm: 4 },
                        height: "76vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        Package Name
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="package_name"
                        value={this.state.package_name}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Duration
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="package_duration"
                        value={this.state.package_duration}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Type
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
           
                      <TextField
                        id="outlined-select-currency"
                        select
                        name='package_type'
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        defaultValue={this.state.package_type}
                      >
                        {['cash','non-cash'].map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            onClick={() =>
                              this.setState({
                                package_type : option
                              })
                            }
                            sx={{ fontSize: 12, fontWeight: "600" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>





                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Discount (%){" "}
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-select-currency"
                        type="number"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        name="package_discount"
                        onChange={this.handleChange}
                        value={this.state.package_discount}
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Select Plan
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        id="outlined-select-currency"
                        select
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        defaultValue={this.state.package_plan_id}
                      >
                        {this.state.planList.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            onClick={() =>
                              this.setState({
                                package_plan_name: option.item?.name,
                                package_plan_id: option.id,
                                package_amount: option.item?.amount / 100,
                              })
                            }
                            sx={{ fontSize: 12, fontWeight: "600" }}
                          >
                            {option.item?.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <br />
                      <br />

                      <Button
                        onClick={this.createPackage}
                        variant="contained"
                        disableElevation
                        size="small"
                        sx={{
                          backgroundColor: "#2486bb",
                          mt: 2,
                          textTransform: "none",
                        }}
                      >
                        Create New Packages
                      </Button>
                      <br />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>

          {
            //// edit packages
          }
          <Box>
            <Modal
              open={this.state.is_edit_package}
              onClose={this.handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: { xs: "90%", sm: "90%", md: "30%", lg: "30%" },
                    height: "90vh",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Paper>
                      <CloseIcon
                        onClick={() =>
                          this.setState({ is_edit_package: false })
                        }
                        sx={{ height: 20, width: 20, color: "#2486bb" }}
                      />
                    </Paper>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: "600",
                      paddingLeft: { xs: 2, sm: 4 },
                      mb: 0,
                    }}
                  >
                    Package details : -
                  </Typography>
                  <Box
                    sx={{
                      height: 2,
                      width: 140,
                      backgroundColor: "#1b7bce",
                      marginLeft: { xs: 2, sm: 4 },
                      mb: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      overflowY: "scroll",
                      "&::-webkit-scrollbar": {
                        width: "5px",
                        borderRadius: 10,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        paddingLeft: { xs: 2, sm: 4 },
                        paddingRight: { xs: 2, sm: 4 },
                        height: "76vh",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {" "}
                        Package Name
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="text"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="package_name"
                        value={this.state.package_name}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Duration
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        type="string"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        name="package_duration"
                        value={this.state.package_duration}
                        onChange={this.handleChange}
                        fullWidth
                        size="small"
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Type
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        id="outlined-select-currency"
                        select
                        name='package_type'
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        defaultValue={this.state.package_type}
                      >
                        {['cash','non-cash'].map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            onClick={() =>
                              this.setState({
                                package_type : option
                              })
                            }
                            sx={{ fontSize: 12, fontWeight: "600" }}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Package Discount (%){" "}
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        size="small"
                        id="outlined-select-currency"
                        type="number"
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        name="package_discount"
                        onChange={this.handleChange}
                        value={this.state.package_discount}
                      />

                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "600",
                          padding: 0.2,
                          mt: 1,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        Select Plan
                        <Typography sx={{ color: "red" }}>*</Typography>
                      </Typography>
                      <TextField
                        id="outlined-select-currency"
                        select
                        InputProps={{ sx: { fontSize: 12, fontWeight: "600" } }}
                        fullWidth
                        defaultValue={this.state.package_plan_id}
                      >
                        {this.state.planList.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            onClick={() =>
                              this.setState({
                                package_plan_name: option.item?.name,
                                package_plan_id: option.id,
                                package_amount: option.item?.amount / 100,
                              })
                            }
                            sx={{ fontSize: 12, fontWeight: "600" }}
                          >
                            {option.item?.name}
                          </MenuItem>
                        ))}
                      </TextField>

                      <br />
                      <br />

                      <Button
                        onClick={this.editPackage}
                        variant="contained"
                        disableElevation
                        size="small"
                        sx={{
                          backgroundColor: "#2486bb",
                          mt: 2,
                          textTransform: "none",
                        }}
                      >
                        Edit Packages
                      </Button>
                      <br />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Modal>
          </Box>
        </Box>
      </div>
    );
  }
}

export default Subscription;


export function Subscriptionc(props){
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams()
  return (<Subscription param={param} navigate={navigate}></Subscription>)
}



const plan_list = ["daily", "weekly", "monthly", "quarterly", "yearly"];

