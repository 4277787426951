import React, { Component } from "react";
import { Sidebarc } from "../Sidebar";
import { Appheaderc } from "../Appheader";
import { Button, Grid2, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Switch,
  InputAdornment,
} from "@mui/material";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";
import PaidIcon from '@mui/icons-material/Paid';
import LogoutIcon from '@mui/icons-material/Logout';
import base_url from "../baseurl";
import moment from 'moment'
import { useLocation, useNavigate, useParams } from "react-router-dom";



const drawerWidth = 80;


class Subscription_desc extends Component {
  constructor(props) {
    super(props);

    this.state = {
        subscription_details : "",
   invoice : [],

   userData : ""
    };
  }



  componentDidMount() {
    
    fetch(`${base_url.base_url}/transaction/subscription/retriveSingleSubscriptionDetails`, {
      headers: {
        "content-type": "application/json",
      },
      method: "post", 
      body: JSON.stringify({
      subscription_id :  this.props.param.id.replace(/:/g,'')
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
       this.setState({
        subscription_details : result.data
       })
      });

      ////// alll invoice retrive lsit    allInvoiceForSubscription
    
      fetch(`${base_url.base_url}/transaction/subscription/retriveAllInvoiceAgainstASubscription`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post", 
        body: JSON.stringify({
        subscription_id :  this.props.param.id.replace(/:/g,'')
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
         this.setState({
          invoice : result.data.items
         })
        });

        //////  subscriptionTakenUserDetails

        fetch(`${base_url.base_url}/transaction/subscription/subscriptionTakenUserDetails`, {
            headers: {
              "content-type": "application/json",
            },
            method: "post", 
            body: JSON.stringify({
            subscription_id :  this.props.param.id.replace(/:/g,'')
            }),
          })
            .then((res) => {
              return res.json();
            })
            .then((result) => {
             this.setState({
             userData  : result.data
             })
            });
  }



  instantRetriveSubscriptionDetails=()=>{
    fetch(`${base_url.base_url}/transaction/subscription/retriveSingleSubscriptionDetails`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post", 
        body: JSON.stringify({
        subscription_id :  this.props.param.id.replace(/:/g,'')
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((result) => {
         this.setState({
          subscription_details : result.data
         })
        });
  }



//////////// status update here //////////

  cancelSubscription=(req,res)=>{
    fetch(`${base_url.base_url}/transaction/subscription/cancelSubscriptionByAdmin`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put", 
      body: JSON.stringify({
      subscription_id :  this.props.param.id.replace(/:/g,'')
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
       this.instantRetriveSubscriptionDetails()
      });

  }

  paushSubscription=(req,res)=>{
    fetch(`${base_url.base_url}/transaction/subscription/pushSubscription`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put", 
      body: JSON.stringify({
      subscription_id :  this.props.param.id.replace(/:/g,'')
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
       this.instantRetriveSubscriptionDetails()
      });
  }

  resumeSubscription=(req,res)=>{
    fetch(`${base_url.base_url}/transaction/subscription/resumeSubscription`, {
      headers: {
        "content-type": "application/json",
      },
      method: "put", 
      body: JSON.stringify({
      subscription_id :  this.props.param.id.replace(/:/g,'')
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
       this.instantRetriveSubscriptionDetails()
      });
  }






  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } ,ml:{sm:10,xs:0} }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 2,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
              Subscription details
              </Typography>

             









              <Paper  elevation={0}>
  <Typography sx={{fontSize:14,pt:2,mb:1,ml:2,color:'#ab266c'}}>SUBSCRIPTIONS DESCRIPTION</Typography>
<Box sx={{p:2}}>
             

<Grid2 container spacing={2}>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>SUBSCRIPTION ID</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.id:""}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>ENTITY</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>SUBSCRIPTION</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>PLAN ID</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.plan_id:""}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>CUSTOMER ID</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.customer_id:""}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>STATUS</Typography>
<Typography sx={{fontSize:12,mt:0.5,backgroundColor:this.state.subscription_details?.status=='cancelled'?'#a72118':'#1b7bce',color:'#fff',fontWeight:'bold',pl:0.5}}>{this.state.subscription_details?this.state.subscription_details.status:""}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>QUANTITY</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.quantity:""}</Typography>
</Grid2>



<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>TOTAL COUNT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.total_count:""}</Typography>
</Grid2>


<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>PAID COUNT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?this.state.subscription_details.paid_count:""}</Typography>
</Grid2>


<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>REMAINING COUNT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.subscription_details?.remaining_count}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>CURRENT START </Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?.current_start).format("MM/DD/YYYY")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>CURRENT END</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?.current_end).format("MM/DD/YYYY")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>START AT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?.start_at).format("MM/DD/YYYY")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>END AT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?.end_at).format("MM/DD/YYYY")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>EXPIRE BY</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?.expire_by).format("MM/DD/YYYY")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Button size="small" onClick={this.cancelSubscription} variant="contained" color="error" disableElevation sx={{fontSize:10,mt:1 }}>Cancel</Button>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Button size="small" variant="contained" onClick={this.resumeSubscription} disableElevation sx={{fontSize:10,mt:1}}>Resume</Button>
<Button size="small" variant="contained" onClick={this.paushSubscription} disableElevation sx={{fontSize:10,mt:1,ml:1}}>Pause</Button>
</Grid2>



</Grid2>








{
    /*
<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'600'}}>CURRENT START </Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?this.state.subscription_details.current_start:"")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'600'}}>CURRENT END</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?this.state.subscription_details.current_end:"")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'600'}}>START AT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?this.state.subscription_details.start_at:"")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'600'}}>END AT</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?this.state.subscription_details.end_at:"")}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'600'}}>EXPIRE BY</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment.unix(this.state.subscription_details?this.state.subscription_details.expire_by:"")}</Typography>
</Grid2>

    */
}







        
                <br/>
 </Box>
</Paper>





<Paper sx={{mt:2}}  elevation={0}>
  <Typography sx={{fontSize:14,pt:2,mb:1,ml:2,color:'#ab266c'}}>SUBSCRIPTIONS TAKEN USER DETAILS</Typography>
<Box sx={{p:2}}>
             

<Grid2 container spacing={2}>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>CUSTOMER EMAIL</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{this.state.userData?this.state.userData.customer_email:""}</Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>CUSTOMER PHONE</Typography>
<Typography sx={{fontSize:12,mt:0.5}}><Typography sx={{fontSize:12,mt:0.5}}>{this.state.userData?this.state.userData.customer_phone:""}</Typography></Typography>
</Grid2>

<Grid2 item size={{xs:6,sm:4,md:2}}>
<Typography sx={{color:'#1b7bce',fontSize:14,fontWeight:'500'}}>SUBSCRIBE DATE</Typography>
<Typography sx={{fontSize:12,mt:0.5}}>{moment(this.state.userData?this.state.userData.subscribe_date:"").format('YYYY-MM-DD')}</Typography>
</Grid2>

</Grid2>















        
                <br/>
 </Box>
</Paper>






<Paper  elevation={0} sx={{mt:2}}>
  <Typography sx={{fontSize:14,pt:2,mb:1,ml:2,color:'#ab266c'}}>Amount Deduction Against This Subscription</Typography>
<Box sx={{p:2}}>
             


                  <TableContainer
                    component={Box}
                    sx={{ backgroundColor: "#f4f4f6" }}
                  >
                    <Table
                      size="small"
                      sx={{ minWidth: 1250 }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                           INVOICE ID
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                           ENTITY
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                            CUSTOMER ID
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                            ORDER ID
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                            SUBSCRIPTION ID
                          </TableCell>
                      
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                          PAYMENT ID
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                            PAID AT
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                           AMOUNT
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              fontSize: 12,
                              color: "#008ffb",
                              fontFamily: "800",
                            }}
                          >
                            {" "}
                           STATUS
                          </TableCell>
                          
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.invoice.map((row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              backgroundColor: index % 2 ? "#f9f9f9" : "#fff",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "#42526e" }}
                            >
                              <Box
                                sx={{
                                  height: 20,
                                  minWidth: 60,
                                  display: "flex",
                                  justifyContent: "left",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: "700",
                                    paddingLeft: 1,
                                    paddingRight: 1,
                                  }}
                                >
                                  {row.id}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                fontWeight: "500",
                                textTransform:'uppercase'
                               
                              }}
                            >
                              {row.entity}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                fontWeight: "600",
                               // textTransform: "uppercase",
                              }}
                            >
                              {row.customer_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                fontWeight: "600",
                                //textTransform: "uppercase",
                              }}
                            >
                              {row.order_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: "#42526e", fontSize: 12,  fontWeight: "600",
                                }}
                            >
                             {row.subscription_id}
                            </TableCell>
            
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                minWidth: 100,
                              }}
                            >
                              {row.payment_id}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                minWidth: 100,
                              }}
                            >
                               {moment.unix(row.paid_at).format('MM-DD-YYYY')}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                minWidth: 100,
                              }}
                            >
                             
                              {row.amount / 100}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                color: "#42526e",
                                fontSize: 12,
                                minWidth: 100,
                              }}
                            >
                             <Button variant="contained" sx={{height:20,fontSize:12}} size="small" >{row.status}</Button>
                            </TableCell>

                            
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>  
                    <Divider />
                  </TableContainer>                
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.invoice.length}
                    rowsPerPage={this.state.invoice.length}
                    page={0}
                  //  onPageChange={this.handleChangePage}
                   // onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />

            


</Box>
</Paper>


















            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 80 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>

     

   
      </div>
    );
  }
}

export default Subscription_desc;

export function Subscription_descc(props){
  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams()
  return (<Subscription_desc param={param} navigate={navigate}></Subscription_desc>)
}
