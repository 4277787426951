import React, { Component } from "react";
import { Box, Card, Paper, SvgIcon, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import logo from "../src/img/logo.png";
import { useNavigate, useLocation, useMatch, Link } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CampaignIcon from '@mui/icons-material/Campaign';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StreamIcon from '@mui/icons-material/Stream';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';



export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openLead: false,
      data: "",
      textcolor: "#008ffb",
      bgcolor: "",

      id: 1,
      pathname: this.props.location.pathname,

      opencompaign: false,
      openrole: false,
    };
  }

  render() {
    return (
      <div>
       <Paper elevation={1} sx={{display:{xs:'none',sm:'block'},backgroundColor:'#444b52',width:80,minHeight:'93vh',borderRadius:0,position:'fixed',mt:5.5,overflowY:'scroll',scrollbarWidth:'none'}}>


<Link to='/dashboard'>
<Box sx={{height:75,width:'100%',backgroundColor: this.state.pathname == "/dashboard" ? "#262e36" : "#444b53" ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<DashboardOutlinedIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Dashboard</Typography>
</Box> 
</Link>


<Link to='/users'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/users" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<GroupIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Users List</Typography>
</Box> 
</Link>



<Link to='/notification'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/notification" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<NotificationsNoneIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Notification</Typography>
</Box> 
</Link>


<Link to='/alerts'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/alerts" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<CampaignIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Alerts</Typography>
</Box> 
</Link>


<Link to='/contact'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/contact" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<StreamIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Contact</Typography>
</Box> 
</Link>

<Link to='/enquiry'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/enquiry" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<ContactPhoneIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Enquiry</Typography>
</Box> 
</Link>


<Link to='/subscriptions'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/subscriptions" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<MonetizationOnIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Subscription</Typography>
</Box> 
</Link>





</Paper>
      </div>
    );
  }
}

export default Sidebar;

export function Sidebarc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return <Sidebar location={location} navigate={navigate}></Sidebar>;
}




/*

<Link to='/settings'>
<Box sx={{height:75,width:'100%',backgroundColor:this.state.pathname == "/settings" ? "#262e36" : "#444b53",display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<SettingsIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Settings</Typography>
</Box> 
</Link>


 <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: 240,
            bottom: 0,
            height: "100vh",
            position: "sticky",
          }}
        >
          <Box
            sx={{
              width: 240,
              marginLeft: 1,
              marginRight: 1,
              borderRadius: 2,
              position: "fixed",
              height: "100vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Paper elevation={1} sx={{ height: 60, backgroundColor: "white" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img style={{height:50,marginTop:5}} src={logo}/>
              
              </Box>
            </Paper>

            <Paper
              elevation={1}
              sx={{ minHeight: "100vh", backgroundColor: "#fff", mt: 1.5 }}
            >
              <br />




              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/dashboard" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/dashboard')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DashboardIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/dashboard" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/dashboard" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Dashboard
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/users" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/users')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <GroupIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/users" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/users" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Users
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/settings" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/settings')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <SettingsIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/settings" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/settings" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Settings
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/notification" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/notification')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  
                  <NotificationsNoneIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/notification" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/notification" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Notification
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>


              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/alerts" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/alerts')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <CampaignIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/alerts" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/alerts" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Alerts
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/contact" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/contact')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <ContactPhoneIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/contact" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/contact" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Contacts
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>




              <Box
                sx={{
                  marginLeft: 1,
                  marginRight: 2,
                  height: 40,
                  backgroundColor:
                    this.state.pathname == "/enquiry" ? "#ebebf5" : "#fff",
                  display: "flex",
                  justifyContent: "left",
                  mt: 1,
                  borderRadius: 2,
                  alignItems: "center",
                }}
                onClick={()=>this.props.navigate('/enquiry')}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "row", width: "100%" }}
                >
                  <DynamicFormIcon
                    sx={{
                      marginLeft: 2,
                      color: this.state.pathname == "/enquiry" ? "#008ffb" : "#8e8e93",
                    }}
                  />
                  <Box sx={{ width: "100%", marginLeft: "25%" }}>
                    <Link style={{ textDecoration: "none" }}>
                      <Typography
                        sx={{
                          textDecoration: "none",
                          textAlign: "left",
                          fontSize: 15,
                          fontWeight: "600",
                          color:
                            this.state.pathname == "/enquiry" ? "#008ffb" : "#212121",
                          marginLeft: -4,
                        }}
                      >
                        Enquiry
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>






              <br />
              <br />
              <br />
            </Paper>
            <br />
          </Box>
        </Box>
*/









/*
<Paper elevation={1} sx={{display:{xs:'none',sm:'block'},backgroundColor:'#444b52',width:80,minHeight:'93vh',borderRadius:0,position:'static'}}>


<Link to='/dashboard'>
<Box sx={{height:75,width:'100%',backgroundColor:'#262e36',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<DashboardOutlinedIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Dashboard</Typography>
</Box> 
</Link>

<Link to='/leads'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<LeaderboardIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Leads</Typography>
</Box>
</Link>


<Link to='/contact'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<ContactPhoneIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Contacts</Typography>
</Box>
</Link>

<Link to='/reminder'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<NotificationsPausedIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Reminder</Typography>
</Box>
</Link>

<Link to='/settings'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<SettingsIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Settings</Typography>
</Box>
</Link>

<Link to='/users'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<GroupIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Users</Typography>
</Box>
</Link>

<Link to='/employees'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<BadgeIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Employees</Typography>
</Box></Link>

<Link to='/automation_connect'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<CableIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Connect</Typography>
</Box></Link>

<Link to='/customer'>
<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<Groups2Icon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Customers</Typography>
</Box></Link>

<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'none',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<DashboardIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Reports</Typography>
</Box>

<Box sx={{height:75,width:'100%',backgroundColor:'#444b52',display:'none',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
<DashboardIcon sx={{height:22,width:22,color:'#fff'}}/>
<Typography sx={{fontSize:11,color:'#fff',mt:0.5}}>Task</Typography>
</Box>


</Paper>
*/
