import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, touchRippleClasses, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,


} from "@mui/material";
import base_url from "./baseurl";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { ToastContainer, toast } from "react-toastify";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Chart from "react-apexcharts";
import "react-toastify/dist/ReactToastify.css";

const drawerWidth = 80;
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      description: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  fieldunfilled = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Fill All Fields{" "}
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };

  Notificationsent = () => {
    toast.info(
      <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
        Notification sent
      </Typography>,
      {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: "🚀",
        theme: "colored",
      }
    );
  };



  sendNotification = () => {
    if (this.state.title !== "" && this.state.description !== "") {
      fetch(`${base_url.base_url}/adminNotification`, {
        headers: {
          "content-type": "application/json",
        },
        method: "post",
        body: JSON.stringify({
          title: this.state.title,
          description: this.state.description,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then(async (result) => {
        this.setState({
          title : "",
          description : "",
        })
          this.Notificationsent();
        });
    } else {
      this.fieldunfilled();
    }
  };


  
  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" ,},ml:{sm:10,xs:0} }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                Notifications
              </Typography>

              <Box>
                <Paper style={{ minHeight: 300, width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: "600",
                        color: "black",
                        mt: 5,
                      }}
                    >
                      Title
                    </Typography>
                    <TextField
                      size="small"
                      value={this.state.title}
                      name="title"
                      onChange={this.handleChange}
                      InputProps={{ style: { fontSize: 13 ,fontWeight:'bold' } }}
                    />

                    <Typography
                      sx={{
                        fontSize: 13,
                        fontWeight: "600",
                        color: "black",
                        mt: 2,
                      }}
                    ></Typography>
                    <TextField
                      id="outlined-multiline-static"
                      label="Description"
                      multiline
                      rows={3}
                      InputProps={{ style: { fontSize: 13 ,fontWeight:'bold' } }}
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />

                    <Button
                    onClick={this.sendNotification}
                      variant="contained"
                      size="small"
                      sx={{ width: 160, marginTop: 3, textTransform: "none" }}
                    >
                      Send notification
                    </Button>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 80 },
            }}
          >
            <Appheaderc />
          </Box>
        </Box>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    );
  }
}

export default Notification;
